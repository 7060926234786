import styled from 'styled-components';

export const Styles = styled.div`
 .pdf-preview-container {
    margin-left: 16px;
    cursor: zoom-in; 
    height: 141px; 
    width: 100px; 
    boxShadow: 0 0 10px rgba(0, 0, 0, 0.4);
    float: right;
  }

  .pdf-preview-inner-container {
    position: relative; 
    width: 100%; 
    height: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); // Make it transparent
    cursor: zoom-in;
  }

  .retry-btn {
    font-size: 12px;
    line-height: 21px;
  }

  .typewriter1 {
    font-size: 16px;
    line-height: 23px;
    white-space: normal;
    max-height: 500px;
    width: 100%;
    // overflow-y: auto;
    scrollbar-color: #b7c3cc #e8e5f8;
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
  }

  .typewriter2 {
    font-size: 16px;
    line-height: 23px;
    white-space: normal;
    max-height: 60vh;
    width: 100%;
    overflow-y: auto;
    scrollbar-color: #b7c3cc #e8e5f8;
  }

  .typewriter3 {
    font-size: 16px;
    line-height: 23px;
    white-space: normal;
    max-height: 189.57px;
    width: 100%;
    // overflow-y: auto;
    scrollbar-color: #b7c3cc #e8e5f8;
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
  }

  .typewriter3::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .typewriter3::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .typewriter4 {
    font-size: 16px;
    line-height: 23px;
    white-space: normal;
    max-height: 20vh;
    width: 100%;
    overflow-y: auto;
    scrollbar-color: #b7c3cc #e8e5f8;
  }

  .card-body1 {
    max-height: 192px;
  }

  .card-body2 {
    max-height: 20vh;
  }

  .card-body-empty1 {
    height: 310.43px;
  }

  .card-body-empty2 {
    height: 30vh;
  }

  .footer-container {
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    padding: 1% 3%;
    width: 100%;
    justify-content: space-between;
  }

  .footer-outer-container {
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    // padding: 1% 3%;
  }

  .footer-inner-container {
    display: flex; 
    flex-direction: column;
    gap: 7px;
    justify-content: center;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 6px 0 6px;
  }
`;