import React, {useContext, useEffect, useState, Suspense, lazy} from 'react';
import { AppContext } from '../../../context';
import Hr from '../../hr';

import { H2, H1, P, A, Span } from '../../theme/typography';
import { Article,Styles } from './styles';
import { FaChevronDown, FaChevronUp, FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import CpdNotes from '../CpdNotes';
import ModalSearchFeedback from '../../modal-search-feedback';
import TypewriterAnimationChat from '../../typewriter/TypewriterAnimationChat';
import PublisherTag from '../../publisher-tag';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen';

const PDFPreview = lazy(() => import("../PDFPreview"));
const ModalPDFPreview = lazy(() => import("../../modal-pdf-preview"));

const WebCard = (props) => {
  const { 
    userContext,
    feedbackContext,
    setFeedbackContext,
    searchContext,
  } = useContext(
    AppContext
  );
  const [iframeAllowed, setIframeAllowed] = useState(false);
  const [showModalFlag, setShowModalFlag] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPDFModalFlag, setShowPDFModalFlag] = useState(false);
  const [cardExpanded, setCardExpanded] = useState(false);
  const isSmallScreen = useIsSmallScreen();
  const handleClickTooltip = (e) => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
  };

  const handleClickTooltipMsg = (e) => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
  };

  var onlineViewUrlParts = [];
  onlineViewUrlParts = props.result.online_view_url.split("/");
  onlineViewUrlParts = onlineViewUrlParts.filter(part => {
    return part !== '';
  });
  onlineViewUrlParts[1] = onlineViewUrlParts[0] + "//" + onlineViewUrlParts[1];
  onlineViewUrlParts.shift();
  var webOnlineViewUrl = onlineViewUrlParts.join(" › ");
  if (webOnlineViewUrl.length > 56)
    webOnlineViewUrl = webOnlineViewUrl.substring(0,53) + "...";

  function isToday(date) {
    const today = new Date();
  
    if(today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate())
      return true;
    return false;
  };

  function isYesterday(date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if(yesterday.getFullYear() === date.getFullYear() && yesterday.getMonth() === date.getMonth() && yesterday.getDate() === date.getDate())
      return true;
    return false;
  };

  const formattedDate = (d) => {
    const date = new Date(d);
    const today = new Date();
    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate())
      return 'Today';

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (yesterday.getFullYear() === date.getFullYear() && yesterday.getMonth() === date.getMonth() && yesterday.getDate() === date.getDate())
      return 'Yesterday';

    const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    return date.getDate() + ' ' + monthAbbr[date.getMonth()] + ' ' + date.getFullYear();
  };

  // Important note: First result is coded inline (made use of the map method)
  const handleCardClick = (e, result, i) => {
    let cardBody = e.currentTarget.querySelector('.card_body');
    if (!cardExpanded) {
      if (showPDFModalFlag === undefined) {
        setCardExpanded(prev => {return true;});
        // result.expanded = 'expanded';
        setFeedbackContext({
          ...feedbackContext,
          chunk_id: result.chunk_id,
          chunk_title:result.chunk_title,
          online_view_url: result.online_view_url,
          requested_obj: result.requested_obj,
          chunk_text: result.text,
          doc_publisher: result.doc_publisher,
          rank: i,
          value: 1,
          feedbackType: 'interact',
          triggerRequest: true,
        });
      }
    } else{
      if (isSmallScreen){
        setCardExpanded(prev=> {return false});
        // result.expanded = undefined;
        setFeedbackContext({
          ...feedbackContext,
          chunk_id: result.chunk_id,
          chunk_title:result.chunk_title,
          online_view_url: result.online_view_url,
          requested_obj: result.requested_obj,
          chunk_text: result.text,
          doc_publisher: result.doc_publisher,
          rank: i,
          value: 1,
          feedbackType: 'interact',
          triggerRequest: false,
        });
      }
    }
  };

  
  const handleUnexpanded = (e, result, i) => {
    setCardExpanded(prev=> {return false;});
    // result.expanded = undefined;
    // result.noteExpanded = false;
    setFeedbackContext({
      ...feedbackContext,
      chunk_id: result.chunk_id,
      chunk_title:result.chunk_title,
      online_view_url: result.online_view_url,
      chunk_text:  result.text,
      doc_publisher: result.doc_publisher,
      rank: i,
      value: 1,
      feedbackType: 'interact',
      triggerRequest: false,
    });
  };

  useEffect (() => {
    if (props.isOnlineViewUrlPdf) {
      //add axios and add-log and redirrect to link
      const config = {
        method: 'post',
        url: `${window.BASE_URL_DOCUMENTS}iframe-allowed`,
        withCredentials : true,
        headers: {'Content-Type': 'application/json' },
        data: JSON.stringify({
          'url': props.result.online_view_url,
          'search_params': userContext.search_params,
        }),
      };
  
      axios(config)
      .then(function(res) {
        if (res.data.allowed) {
          setIframeAllowed(true);
        } else {
          setIframeAllowed(false);
        };
      })
      .catch (function(error){
        console.log('error is: \n', error);
        setIframeAllowed(false);
      });
    };
  },[]);

  //this can not happen because webresult do not need token 

  // useEffect (() => {
  //   if (requestExpiryToken !== '' && url !== '' )
  //   {
  //     //add axios and add-log and redirrect to link
  //     // const config = {
  //     //   method: 'post',
  //     //   url: `${window.BASE_URL_USER}get-expiry-token`,
  //     //   withCredentials : true,
  //     //   headers: {'Content-Type': 'application/json' },
  //     //   data: JSON.stringify({'requested': requestExpiryToken}),
  //     // };
  
  //     // axios(config)
  //     // .then(function(res){
  //       if (userContext['search_params']){
  //         setPrivateToken(userContext['search_params']);
  //         let url_token = '';
  //         if(hasOnlinePdfUrl){
  //           let s =  urlPdf.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
  //           if (s!== urlPdf){
  //             url_token = s; 
  //           }
  //         }
  //         window.open(url_token,'_blank','noreferrer');
  //       }
  //       else{
  //         // if 400 previours url
  //           console.log('not found');
  //       }
  //     setRequestExpiryToken('');
  //   }
  // },[requestExpiryToken]);
  const callbackResultExpanded = (result) => {
    return () => {
      setCardExpanded(prev => {return true});
      // result.expanded = 'expanded';
      // result.noteExpanded = true;
    };
  };

  const handleModalClick = (e, i) => {
    e.stopPropagation();
    setShowPDFModalFlag(i);
  };


  return (
    <Article className={(props.isOnSearchPage && 'card_chat_on_search_container')}>
      <div className={"card " + (!props.isSearchResult && 'card_chat ') + (props.isOnSearchPage && 'card_chat_on_search')} onClick={(e) => handleCardClick(e, props.result, props.i)}>
        <div className="card_top">
          {props.isSearchResult && cardExpanded &&  !isSmallScreen && (
            <span onClick={(e) => handleUnexpanded(e, props.result, props.i)}
              className={`card_expandIcon ` + cardExpanded  }>
              <FaChevronUp  />
            </span>
          )}
          {props.isSearchResult && !cardExpanded &&  !isSmallScreen && (
            <span
              className={`card_expandIcon ` + cardExpanded }>
              <FaChevronDown />
            </span>
          )}
          <div className="main-result-body">
            {!isSmallScreen && iframeAllowed && (
              <div className={`pdf-preview-container`} onClick={(e)=>handleModalClick(e, props.i)}>
                <Suspense fallback={<div></div>}>
                  <PDFPreview data={props.result.online_view_url} />
                </Suspense>
              </div>
            )}

            <div className={`${isSmallScreen ?"card_header_mobile":"card_header"} ${iframeAllowed && "card_header_left"}`}>
              {isSmallScreen ? (
                <>
                {props.isSearchResult ? (
                  <H1 h1 regular className='mobile_title'>
                    <a
                      href={props.result.online_view_url}
                      onClick={(e) => props.handleLinkoutClick(e, props.result)}
                      target="_self"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} 
                    />
                    {props.result.online_view_url && (
                      <div style={{marginBottom: "3px", lineHeight: "14px"}}>
                        <span className={isSmallScreen ? "mobileDate" : ""}>
                          {props.result.online_view_url && webOnlineViewUrl}
                        </span>
                      </div>
                    )}
                  </H1> 
                ) : ( // Mobile Assisted AI: yellow text + url
                  <div className='card_body_container'>
                    <div className='card_body expanded' aria-expanded={true}>
                      {props.result.modified_date && isSmallScreen ? (
                        <div className="search_card_table_wrapper_mobile"
                        dangerouslySetInnerHTML={{ __html: formattedDate(props.result.modified_date) + ' — ' + props.purifiedHtml }} />  
                      ) : (
                        <div className="search_card_table_wrapper_mobile"
                        dangerouslySetInnerHTML={{ __html: props.purifiedHtml }} />  
                      )}    
                    </div>
                    {props.result.online_view_url && (
                      <div style={{marginBottom:"3px", lineHeight: "14px"}}>
                          <span className={isSmallScreen?"mobileDate":""}>
                            {props.result.online_view_url && webOnlineViewUrl}
                          </span>
                      </div>
                    )}
                  </div>
                )}
                </>
              ):(
                <>
                  {props.isSearchResult ? (
                    <div className='title-div' style={{display:"flex", justifyContent: "space-between", alignItems: "center", position: "relative"}}>
                      <H2 h2 semibold>
                        <a
                          href={props.result.online_view_url}
                          onClick={(e) => props.handleLinkoutClick(e, props.result)}
                          target="_blank"
                          rel="noopener noreferrer"
                          dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} 
                        />
                      </H2> 
                      {showTooltip 
                        ? <p className="tooltip-msg" onClick={handleClickTooltipMsg}>This website is not part of your curated sources.</p> 
                        : <FaInfoCircle onClick={handleClickTooltip} className="tooltip ml-2" title="This website is not part of your curated sources." />
                      }
                    </div>
                  ) : (
                    <div className='card_body_container'>
                      <div className={`card_body expanded`} aria-expanded={true}>
                        {props.isOnSearchPage ? (
                          <div className="search_card_table_wrapper" dangerouslySetInnerHTML={{ __html: props.purifiedHtml }} /> 
                        ) : (
                          <div className="search_card_table_wrapper">
                            <TypewriterAnimationChat string={props.purifiedHtml}/>  
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {!props.isSearchResult && (
              <>
                {/* <div className='score1'>
                  <span className='score'>Consensus score: {props.result.result_consensus_score}%</span>
                </div> */}
                <Hr style={{'marginTop': '5px', 'marginBottom': '5px'}}/>
             </>
            )}

            {!props.isSearchResult ? (
              <>
              {!isSmallScreen ? (
                <div className='title-div' style={{display:"flex", justifyContent: "space-between", alignItems: "center", position: "relative"}}>
                  <H2 h2 semibold>
                    <a
                      href={props.result.online_view_url}
                      onClick={(e) => props.handleLinkoutClick(e, props.result)}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} />
                  </H2> 
                  {showTooltip 
                    ? <p className="tooltip-msg" onClick={handleClickTooltipMsg}>This website is not part of your curated sources.</p> 
                    : <FaInfoCircle onClick={handleClickTooltip} className="tooltip ml-2" title="This website is not part of your curated sources." />
                  }
                </div>
              ) : ( // Mobile Assisted AI: Dark blue headline
                <div className="card_header_mobile">
                  <H1 h1 regular className='mobile_title'>
                    <a
                      href={props.result.online_view_url}
                      onClick={(e) => props.handleLinkoutClick(e, props.result)}
                      target="_self"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} 
                    />
                  </H1> 
                </div>
              )}
              </>
            ) : (
              <div className='card_body_container'>
                <div className={ cardExpanded? "card_body expanded":"card_body"} aria-expanded={props.i === -1 ? true : false}>
                  {props.result.modified_date && isSmallScreen ? (
                    <div className={isSmallScreen? "search_card_table_wrapper_mobile":"search_card_table_wrapper"}
                    dangerouslySetInnerHTML={{ __html: formattedDate(props.result.modified_date) + ' — ' + props.purifiedHtml }} />  
                  ) : (
                    <div className={isSmallScreen? "search_card_table_wrapper_mobile":"search_card_table_wrapper"}
                    dangerouslySetInnerHTML={{ __html: props.purifiedHtml }} />  
                  )}    
                </div>
              </div>
            )}
          </div>

          <div className="card_footer">
            <div className="card_footer_1">
              <div>
                {props.result.doc_publisher && (props.result.doc_publisher !== 'Unknown' && props.result.doc_publisher !== 'unknown') && (
                  // <PublisherTag publisher={get_publisher(props.result.doc_publisher)} isFolder={props.result.parent_folder}
                  <PublisherTag publisher={props.publisher} isFolder={props.result.parent_folder}
                  requestToken={props.result.requested_obj ? props.result.requested_obj : ""} inMobileResult ={ isSmallScreen} />
                )}
                {!isSmallScreen && (
                  <A
                  c2
                  semibold
                  textlink
                  className="card_footer_1--external"
                  onClick={(e) => props.handleLinkoutClick(e, props.result)}
                  href={props.result.online_view_url}
                  target={isSmallScreen? "_self" :"_blank"}
                  rel="noopener noreferrer"
                  >
                    {webOnlineViewUrl}
                  </A>
                )}
              </div>
              {!isSmallScreen && props.result.modified_date && props.result.modified_date != ""  && (
                <div className="card_footer_1--retrieved">
                  <Span c2 regular textplaceholder>
                    Last updated:{' '}
                    <Span c2 semibold textoffblack>
                      {isToday(new Date(props.result.modified_date)) ? "Today" : isYesterday(new Date(props.result.modified_date)) ? "Yesterday" : (new Date(props.result.modified_date)).toString().substring(4, 15)}
                    </Span>
                  </Span>
                </div>
              )}
            </div>
          </div>
        </div>

        {props.isSearchResult && (
          <div className="card_footer">
            <div className={cardExpanded&& !isSmallScreen 
              ? "card_footer_2 card_footer_2_expanded" 
              : cardExpanded ?"card_footer_2 card_footer_2_expanded":"card_footer_2 card_footer_2_undefined"}>
              
              {!isSmallScreen && (props.isLoggedin && !props.isMagicUser) && props.bookmarkedFetched && (
                <>
                  <Hr style={{ margin: '16px -56px 0 -32px' }} />
                  <div className="cpd_container">
                    <CpdNotes isLoggedin={props.isLoggedin } bookmarks={props.hasBookmarks} updateBookmarks={props.updateBookmarks} setUpdateBookmarks={props.setUpdateBookmarks} 
                      isFavourite={props.isFavourite} updateFavourites={props.updateFavourites} favouriteID={props.favouriteID} isRemove={props.toBeRemove} 
                      search={searchContext} parentExpanded={cardExpanded} noteExpanded={props.result.noteExpanded} result={props.result} index={props.i} 
                      expandParent={callbackResultExpanded(props.result)}>
                    </CpdNotes>               
                    <div className="card_footer--feedback">
                        <div className="tooltip" 
                        onClick={()=>setShowModalFlag(props.i)}  
                        title="Didn't find what you were looking for? Let us know" >Feedback
                        </div>
                    </div>
                  </div>
                  
                </>
              )}
              {!isSmallScreen && (!props.isLoggedin || props.isMagicUser) && (
                <>
                  <Hr style={{ margin: '16px -56px 0 -32px' }} />
                  <div className="cpd_container"> 
                    <CpdNotes isLoggedin={props.isLoggedin && !props.isMagicUser} bookmarks={props.hasBookmarks} search={searchContext}
                      parentExpanded={cardExpanded} noteExpanded={props.result.noteExpanded} result={props.result}
                      index={props.i} expandParent={(props.result, props.i)}>
                    </CpdNotes>
                    <div className="card_footer--feedback">
                        <div className="tooltip" 
                        onClick={()=>setShowModalFlag(props.i)}  
                        title="Didn't find what you were looking for? Let us know" >Feedback
                        </div>
                    </div>
                  </div>
                </>
              )}
              {typeof showModalFlag !== 'undefined' &&  showModalFlag === props.i &&(
              <ModalSearchFeedback
                modalFeedbackShow={ showModalFlag=== props.i }
                modalFeedbackClose={setShowModalFlag} 
                title=" Let us know how to improve medwise.ai "
                result = {props.result}
                i = {props.i} 
                />
                )}
            </div>
          </div>
        )}

        {typeof showPDFModalFlag !== 'undefined' &&  showPDFModalFlag === props.i &&(
          <Suspense fallback={<div></div>}>
            <ModalPDFPreview
              modalPDFShow={ showPDFModalFlag=== props.i }
              modalPDFClose={setShowPDFModalFlag} 
              title={props.result.chunk_title}
              result = {props.result}
              i = {props.i} 
            />
          </Suspense>
        )}
      </div>
    </Article>
  );
};

export default WebCard;
